<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST BANK DEPOSITORY</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md3>
          <v-select
            v-model="category_id"
            class="mx-2"
            dense
            outlined
            label="Category"
            :items="category_items"
            item-value="id"
            item-text="category"
            @change="selected_category"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3>

          <v-select
            v-model="type_of_bank"
            class="mx-2"
            dense
            outlined
            label="Purpose"
            :items="['TRUST FUND','EXPENSE','RESERVE FUND','INVESTMENT','REMITTANCE']"
            @change="selected_category"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-simple-table dense class="mt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-uppercase">
              No.
            </th>
            <th class="text-uppercase">
              Bank Code
            </th>
            <th class="text-uppercase">
              Purpose
            </th>
            <th class="text-uppercase">
              Account Type
            </th>
            <th class="text-uppercase">
              Bank Name
            </th>
            <th class="text-uppercase">
              Account #
            </th>
            <th class="text-uppercase">
              Address
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in active_items"
            :key="item.id"
          >
            <td>
              {{ (active_items
              .map(function (x) {
              return x.id
              })
              .indexOf(item.id)+1) }}
            </td>
            <td>
              {{ item.bank_code }}
            </td>
            <td>
              {{ item.type_of_bank }}
            </td>
            <td>
              {{ item.bank_details }}
            </td>
            <td>
              {{ item.bank_name }}
            </td>
            <td>
              {{ item.account_no }}
            </td>
            <td>
              {{ item.bank_address }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiCheck, mdiClose} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving_data: false,
      alert: false,
      alert_message: '',

      account_type: '',
      category_id: '',
      category_items: [],
      active_items: [],
      deactive_items: [],
      bank_address: '',
      type_of_bank: '',
      bank_details: '',
      account_no: '',
      bank_code: '',
      bank_name: '',

      is_have_checkbook: false,
      is_confidential: false,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['position']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('bank_depository', ['initialize_bank_depository', 'bank_depository_type_of_bank', 'register_new_bank_depository']),
      initialize_data() {
        this.initialize_bank_depository()
          .then(response => {
            this.category_items = response.data
            this.category_items.splice(0, 1)
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_category() {
        const data = new FormData()
        this.bank_details = ''
        data.append('category_id', this.category_id);
        data.append('type_of_bank', this.type_of_bank);
        this.bank_depository_type_of_bank(data)
          .then(response => {
            this.active_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      save_request() {
        this.saving_data = true
        this.alert = false
        var cat = this.category_id
        if (this.$refs.form2.validate()) {
          const data = new FormData()
          data.append('category_id', this.category_id);
          data.append('bank_name', this.bank_name.toUpperCase());
          data.append('bank_address', this.bank_address.toUpperCase());
          data.append('bank_code', this.bank_code.toUpperCase());
          data.append('account_no', this.account_no);
          data.append('is_have_checkbook', this.is_have_checkbook);
          data.append('is_confidential', this.is_confidential);
          data.append('type_of_bank', this.type_of_bank);
          data.append('bank_details', this.bank_details);
          this.register_new_bank_depository(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                var color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              Object.assign(this.$data, initialState())
              this.initialize_data()
              this.category_id = cat
              this.selected_category()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
    },
  }
</script>
